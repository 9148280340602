<template>
  <a-row style="width:100%;">
    <a-col :sm="24" :md="16" :xl="16" style="padding-left:0px;">
      <a-card class="card-operation" :bordered="false">
        <a-row :gutter="24">
          <a-col :sm="24" :md="10" :xl="10" :span="12">
            <a-row :gutter="24"><span style="margin-bottom: 20px"><b>{{ $t("route.LatestProductScanned") }}</b></span></a-row>
            <a-row :gutter="24"><span><FileSearchOutlined style="font-size:30px; margin-bottom:10px;"/>&nbsp &nbsp &nbsp {{ lastItem.product_sku }} {{ ( lastItem.product_size ) }}</span></a-row>
            <a-row :gutter="24"><span><BorderlessTableOutlined style="font-size:30px; margin-bottom:10px;"/>&nbsp &nbsp &nbsp {{ lastItem.rma_id }}</span></a-row>
            <a-row :gutter="24"><span><CalendarOutlined style="font-size:30px;"/>&nbsp &nbsp &nbsp{{ lastItem.receive_item_date }}</span></a-row>
          </a-col>
          <a-col :sm="24" :md="14" :xl="14" :span="12">
            <div style="float:right; min-heigth: 223px;">
              <a-image class="img_last_product_scan" width="135px" size="large" shape="square" :src="lastItem.product_image"/>
            </div>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :sm="24" :md="8" :xl="8" >
      <a-card class="dashboard-operation" :bordered="false">
        <a-row >
          <a-col :span="24" style="text-align: center;margin-bottom: 20px;">
            <a-row :gutter="24">
              <a-col :sm="24" :md="10" :xl="10" :span="12">
                <div class="wrapper2">
                  <span class="badge2" style="font-weight: 900">{{ scannedItemCount }}</span>
                </div>
              </a-col>
              <a-col :sm="24" :md="14" :xl="14" :span="12">
                <div style="float:left; min-heigth: 223px;margin-top:25px;">
                  <b>{{ $t("route.Adding") }}</b>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24" style="text-align: center;">
            <a-row :gutter="24">
              <a-col :sm="24" :md="10" :xl="10" :span="12">
                <div class="wrapper2">
                  <span class="badge3" style="font-weight: 900">{{ unScannedItemCount }}</span>
                </div>
              </a-col>
              <a-col :sm="24" :md="14" :xl="14" :span="12">
                <div style="float:left; min-heigth: 223px;margin-top:25px;">
                  <b>{{ $t("route.Pending") }}</b>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24" style="text-align: center; padding:16px 0;">
            <span style="color:#FF4D4D;margin-top:20px"><b>*{{ $t("route.RedIsUnscannedProduct") }}</b></span> <br/>
            <span style="color:#86D067;"><b>*{{ $t("route.GreenIsScannedProduct") }}</b></span>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
  import { ref } from 'vue'
  import {
    /*  UserOutlined,
      ScanOutlined,
      ExclamationCircleOutlined,
      UserSwitchOutlined,
      BarcodeOutlined,  */
    CalendarOutlined,
    FileSearchOutlined,
    BorderlessTableOutlined
  } from '@ant-design/icons-vue';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'LastProduct',
    components: {
      CalendarOutlined,
      FileSearchOutlined,
      BorderlessTableOutlined
    },
    props: {
      lastItem: {
        type: Object,
        default: ()=>{}
      },
      scannedItemCount: {
        type: Number,
        default: ()=>0
      },
      unScannedItemCount: {
        type: Number,
        default: ()=>0
      }
    },
    setup(props) {

      const { t ,locale } = useI18n({ useScope: 'global' })

      return {
        CalendarOutlined,
        FileSearchOutlined,
        BorderlessTableOutlined,
        locale, t
      }
    }
  }
</script>

<style lang="scss" scoped>
.remark{

}

.wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge2 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #86D067;
  color: #fefefe;
}

.badge3 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #FF4D4E;
  color: #fefefe;
}

</style>
<style>
.card-operation.ant-card .ant-card-body{
  padding-bottom: 0;
}
.dashboard-operation.ant-card .ant-card-body{
  padding-bottom: 0;
}
</style>